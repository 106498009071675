@tailwind base;
@tailwind components;
@tailwind utilities;


body,html{
    height: 100%;
}

#root{
  height: 100%
}

.activeTab{
  background-color: #FFF5F5;
  color: #F44336;
  
  
}

.activeheader{
  color: #F44336;
}

input.PhoneInputInput{

  height: 50px;
  
}

input.PhoneInputInput:focus-visible{
  border: none !important;
  
}

*:focus {
  outline: none;
}



.activeColor {
  background-color: #F44336;
}

.activeTextColor{
  color:#F44336
}


.popup-content {
  width: 28% !important
}

    /* popup media screen */
@media (min-width:1025px) and (max-width:768px){
  .popup-content {
    width: 50% !important
  }
}


@media screen and (max-width:768px){
  .popup-content {
    width: 70% !important
  }
}



.fade-in-left {
	-webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}



/* ----------------------------------------------
 * Generated by Animista on 2022-12-29 16:49:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}




